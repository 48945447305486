export default [
  {
    key: 'title',
    label: 'Judul',
    required: true,
  },
  {
    key: 'description',
    label: 'Deskripsi',
    type: 'textarea',
  },
  {
    key: 'started_at',
    label: 'Dimulai pada',
    type: 'datetime',
    required: true,
  },
  {
    key: 'ended_at',
    label: 'Berakhir pada',
    type: 'datetime',
    required: true,
  },
  {
    key: 'is_active',
    label: 'Status',
    type: 'radio',
    required: true,
    options: [
      {
        value: true,
        text: 'Aktif',
      },
      {
        value: false,
        text: 'Tidak Aktif',
      },
    ],
  },
  {
    type: 'section',
    title: 'Banner dan Popup',
  },
  {
    key: 'banner_media_id',
    label: 'Foto banner',
    type: 'image',
  },
  {
    key: 'banner_url',
    label: 'Banner URL',
  },
  {
    key: 'popup_media_id',
    label: 'Foto banner',
    type: 'image',
  },
  {
    key: 'popup_url',
    label: 'Popup URL',
  },
]
